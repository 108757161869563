import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import boletador from "./operacoes/boletador";
import fundos from "./cadastros/fundos";
import ativos from "./cadastros/ativos";
import agentesMercados from "./cadastros/agentes-mercados";
import incluirEditarBoleta from "./operacoes/incluir-editar-boleta";
import preBoletasPendentes from "./operacoes/pre-boletas-pendentes";
import batimentoBoletas from "./operacoes/batimento-boletas";
import relatoriosMovimentacoes from "./relatorios/movimentacoes";
import configuracoes from "./cadastros/configuracoes";
import controleDeVencimentos from "./relatorios/controle-de-vencimentos";

const rootReducer = combineReducers({
  customizer:customizer,
  auth: auth,
  navbar: navbar,
  boletador: boletador,
  fundos:fundos,
  ativos:ativos,
  agentesMercados:agentesMercados,
  incluirEditarBoleta: incluirEditarBoleta,
  preBoletasPendentes: preBoletasPendentes,
  batimentoBoletas: batimentoBoletas,
  relatoriosMovimentacoes: relatoriosMovimentacoes,
  configuracoes: configuracoes,
  controleDeVencimentos: controleDeVencimentos
})

export default rootReducer
