import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import auth from "services/auth";


const UserDropdown = props => {

  const handleLogout = (e) => {
    e.preventDefault();
    auth.clearToken();
    auth.clearUserInfo();
    history.push("/")
  };

  return (
    <DropdownMenu right>
      <DropdownItem
        tag="a"
        onClick={handleLogout}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

const getInitialLettersCompleteName = (userName) => {
  if(!userName){
    return;
  }

  const initials = userName.substr(0,2);
  return initials.toUpperCase();
}

const NavbarUser = props => {

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none user-name ">
              <span className="user-name text-bold-600">
                {getInitialLettersCompleteName(props.userName)}
              </span>
            </div>
          </DropdownToggle>
          <UserDropdown {...props} />
        </UncontrolledDropdown>
      </ul>
    )
}
export default NavbarUser
