import styled from 'styled-components';

export const ButtonIncluirBoleta = styled.button`
  border: 1px solid white !important;
  color: #000;
  border-radius: 18px !important;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  background-color: #fff!important;
  padding: 0.5rem 2rem 0.5rem 0px;
  margin-right: auto!important;
  font-weight: bold;
  font-size: 0.85rem!important;
`
export const ButtonDark = styled.button`
    /* background-color: #2b3340!important; */
    text-align: center;
    background-color: transparent;
    border: 0 solid transparent;
    padding: 0.9rem 2rem;
    border-radius: 6px!important;
    font-size: 0.85rem!important;
    color: #fff;
    &:hover {
        box-shadow: 0 8px 25px -8px #c5cad2;
    }
`


export const ButtonBack  = styled.button`
    background: unset !important;
    border: unset !important;
`

export const  ButtonStep = styled.button`
    
    background: unset;
    border: unset;

    svg {
        color: rgb(43, 51, 64);
        background: #31cf74;
        height: 34px;
        width: 34px;
        font-size: 23px;
        border-radius: 8px;
        padding: 5px;
    }

    span {

    }

`

export const SelectCustom  = styled.select` 
    display: block;
    border: unset;
    width: 90%!important;
`


export const SelectCustomIndicador  = styled.select` 
    margin-left: 2px;
    color: #fff;
    background: #2b3340;
    border-radius: 10px;
`

export const SelectCustomFundo  = styled.select` 
    display: block;
    border: unset;
    padding: 15px 23px 15px 33px;
    background: #77d5b7;
    font-weight: 500;   
`

export const StyleStatusBoletador  = styled.span` 
    border-radius: 37px;
    background-color: ${props => props.status};
    padding: 4px;
    font-size: 0.85rem;
`

export const HeadCustomSelect = styled.h6` 
    font-size: 0.85rem;
`

export const CalculoNegociadoStyle = styled.div` 
        padding: 10px;
        background: #CFCFCF;
        border-radius: 10px;
        text-align: center;
        font-size:0.85rem;

        span {
            font-weight: bold;
        }
`



