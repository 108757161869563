const initialState = {
    data: [],
    params: null,
    allData: [],
    totalPages: 0,
    filteredData: [],
    totalRecords: 0,
    sortIndex: [],
    updateSuccess: false,
    error:false,
    fundos: [],
    corretoras: [],
    listaClasseAtivos: []
  }

  const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr?.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr?.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [arr.length - parseInt(params.perPage), arr.length]
      return (arr3 = finalArr)
    }
  }

const PreBoletasPendentesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PRE_BOLETAS_PENDENTES_INIT":
            return {
                ...state,
                data: action.data,
                toggle: !state.toggle
        }
        case "GET_PRE_BOLETAS_PENDENTES_FUNDOS":
            return {
                ...state,
                fundos: action.data,
        }
        case "GET_PRE_BOLETAS_PENDENTES_CORRETORAS":
            return {
                ...state,
                corretoras: action.data,
        }
        case "GET_PRE_BOLETAS_PENDENTES_CLASSE_ATIVO":
          return {
              ...state,
              listaClasseAtivos: action.data,
        }
        case "GET_PRE_BOLETAS_PENDENTES_UPDATE":
           let indexUpdate = state.data.findIndex(item => item.id === action.data.id)
          
           let updateData = state.data.filter(item => item.id !== state.data[indexUpdate].id)
      
           
           return { ...state, data:updateData,updateSuccess: true   
           }  
        case "UPDATE_PRE_BOLETA_PENDENTE_FLAG":
            let updateFlag =   { ...state, ...action.obj } ;
           return  updateFlag;     
        default:
            return state
        }
}

export default PreBoletasPendentesReducer