export const login = (state = {user:{} }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, user: action.payload }
    }
    case "LOGIN_WITH_JWT_ERROR": {
      return { ...state, error: action.payload }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    default: {
      return state
    }
  }
}
