const initialState = {
    fundos: [],
    listaDatas:[],
    listaPrecoExercicio: [],
    listaCodigoAtivo: [],
    listaCodigoAtivoDataVencimento: [],
    corretoras: [],
    boletaAtivoEdit: [],
    codigoNegociacao:[],
    addSuccess: false,
    updateSuccess:false,
    corretoras: [],
    emissores:[],
    indicadores:[],
    indicadoresLFs:[],
    indicadoresCDBs:[],
    indicadoresSwaps:[],
    indicadoresNDFs:[],
    pontaAtiva:[],
    pontaPassiva:[],
    error: false,
    errors:[],
    pontaAtivaPontaPassiva:[]
  }

const IncluirBoletaReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_INCLUIR_BOLETA_FUNDOS_INIT":
            return {
                ...state,
                fundos: action.data,
        }
        case "GET_LIST_DATAS_TIPO_OPCAO":
            return {
                ...state,
                listaDatas: action.data,
        }
        case "GET_LIST_DATAS_CLASSE_ATIVO":
            return {
                ...state,
                classeAtivo: action.data,
        }
        case "GET_LIST_PRECO_EXERCICIO":
            return {
                ...state,
                listaPrecoExercicio: action.data,
        }
        case "ADD_BOLETADOR":
           
            return {
                ...state,
                addSuccess:true,
            }
        case "ADD_UPDATE_BOLETA":
            if(state?.boletaAtivoEdit?.id){
                return {
                    ...state,
                    updateSuccess:true,
                }
            }
            return {
                    ...state,
                    addSuccess:true,
            }
        case "GET_BOLETA_ATIVO":
           
            return {
                ...state,
                boletaAtivoEdit:action.data,
           }
        case "GET_BOLETA_PONTA_ATIVA":
           
            return {
                ...state,
                pontaAtiva:action.data,
        }
        case "GET_BOLETA_PONTA_ATIVA_PONTA_PASSIVA":
           
            return {
                ...state,
                pontaAtivaPontaPassiva:action.data,
        }
        case "GET_BOLETA_PONTA_PASSIVA":
           
            return {
                ...state,
                pontaPassiva:action.data,
        }      
        case "CLEAR_BOLETA_ATIVO_EDIT":
           
            return {
                ...state,
                boletaAtivoEdit:[],
           }   
        case "UPDATE_BOLETA_DATA":
            return {
                ...state,
                updateSuccess: true,
           }
        case "UPDATE_BOLETA_ADD_EDIT_FLAG":
            let updateFlag =   { ...state, ...action.obj } ;
 
           return  updateFlag; 
        case "GET_LISTA_CODIGO_ATIVO":
            return {
                ...state,
                listaCodigoAtivo: action.data,
        }
        case "GET_LISTA_CODIGO_ATIVO_DATA_VENCIMENTO":
            return {
                ...state,
                listaCodigoAtivoDataVencimento: action.data,
        }
        case "GET_CODIGO_NEGOCIACAO":
            return {
                ...state,
                codigoNegociacao: action.data,
        }
        case "GET_LISTA_CORRETORAS":
            return {
                ...state,
                corretoras: action.data,
        }
        case "GET_LISTA_EMISSORES":
            return {
                ...state,
                emissores: action.data,
        }
        case "GET_LISTA_INDICADORES":
            return {
                ...state,
                indicadores: action.data,
        }
        case "GET_LISTA_INDICADORES_LFs":
            return {
                ...state,
                indicadoresLFs: action.data,
        }
        case "GET_LISTA_INDICADORES_CDBs":
            return {
                ...state,
                indicadoresCDBs: action.data,
        }
        case "GET_LISTA_INDICADORES_SWAPS":
            return {
                ...state,
                indicadoresSwaps: action.data,
        }
        case "GET_LISTA_INDICADORES_NDFS":
            return {
                ...state,
                indicadoresNDFs: action.data,
        }
        case "BOLETA_ERROR":

            return {
              ...state,
              errors: action?.payload?.data?.message,
              error:true,
        }      
                
        default:
            return state
        }
}

export default IncluirBoletaReducer