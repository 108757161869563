const initialState = {
    data: [],
    gestores: [],
    administradores: [],
    params: null,
    allData: [],
    totalPages: 0,
    filteredData: [],
    totalRecords: 0,
    sortIndex: [],
    addSuccess:false,
    updateSuccess: false,
    deleteSuccess:false,
    error:false,
  }
  
  const moveIndex = (arr, from, to) => {
    let el = arr[from]
    arr.splice(from, 1)
    arr.splice(to, 0, el)
  }
  
  const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [arr.length - parseInt(params.perPage), arr.length]
      return (arr3 = finalArr)
    }
  }
  
  const AgenteMercadoReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_AGENTE_MERCADO_DATA":
        return {
          ...state,
          data: action.data,
          params: action.params,
          sortIndex: getIndex(
            state.allData,
            action.data,
            state.sortIndex,
            action.params
          ),
          addSuccess:false
        }
      case "FILTER_AGENTE_MERCADO_DATA":
        let value = action.value
        let filteredData = []
        if (value.length) {
          filteredData = state.allData
            .filter(item => {
              let startsWithCondition =
                item.Name?.toLowerCase().startsWith(value.toLowerCase()) ||
                item.id === value //||
                //item.AGENTE_MERCADO.toLowerCase().startsWith(value.toLowerCase())
  
              let includesCondition =
                item.Name?.toLowerCase().includes(value.toLowerCase()) 
  
              if (startsWithCondition) {
                return startsWithCondition
              } else if (!startsWithCondition && includesCondition) {
                return includesCondition
              } else return null
            })
            //.slice(state.params.page - 1, state.params.perPage)
          return { ...state, filteredData }
        } else {
          filteredData = state.data
          return { ...state, filteredData }
        }
      case "ADD_AGENTE_MERCADO_DATA":
        let data = [...state.data]
        let allData = [...state.allData]
        data.unshift({
          ...action.data,
        })
        allData.unshift({
          ...action.data,
        })
          moveIndex(
            data,
            data.findIndex(item => item.id === action.data.id),
          0
        )  
        return {
          ...state,
          data: data,
          totalRecords: state.allData.length,
          sortIndex: getIndex(state.allData, data, state.sortIndex),
          addSuccess:true,
          allData:allData
        }
        case "ADD_AGENTE_MERCADO_DATA_ERROR":

          return {
            ...state,
            errors: action.payload.data,
            error:true,
          }  
      case "UPDATE_AGENTE_MERCADO_DATA":
         let dataUpdate = [...state.data]
        let indexUpdate = state.data.findIndex(item => item.id === action.data.id)
        dataUpdate[indexUpdate] = action.data
        return { ...state, data:dataUpdate,updateSuccess: true   }
      case "UPDATE_AGENTE_MERCADO_FLAG":
          let updateFlag =   { ...state, ...action.obj } ;
         return  updateFlag;
      case "DELETE_AGENTE_MERCADO_DATA":
        let index = state.data.findIndex(item => item.id === action.obj.id)
        let updatedData = [...state.data]
        updatedData.splice(index, 1)
        return {
          ...state,
          deleteSuccess: true,
          data: updatedData,
          totalRecords: state.allData.length,
          sortIndex: getIndex(
            state.allData,
            state.data,
            state.sortIndex,
            state.params
          )
        }
      default:
        return state
    }
  }
  
  export default AgenteMercadoReducer
  