
import * as Icon from "react-feather"
import React, { Suspense, lazy } from "react";
const Home = lazy(() => import("../views/pages/home/Home"));
const Login = lazy(() => import("../views/pages/authentication/login/Login"));
const AgentesMercados = lazy(() => import("../views/pages/cadastros/agentes-mercados/AgentesMercados"));
const Fundos = lazy(() => import("../views/pages/cadastros/fundos/Fundos"));
const Ativos = lazy(() => import("../views/pages/cadastros/ativos/Ativos"));
const Configuracoes = lazy(() => import("../views/pages/cadastros/configuracoes/Configuracoes"));
const IncluirEditarBoleta = lazy(() => import("../views/pages/operacoes/incluir-editar-boleta/IncluirEditarBoleta"));
const Boletador = lazy(() => import("../views/pages/operacoes/boletador/Boletador"));
const PreBoletasPendentes = lazy(() => import("../views/pages/operacoes/pre-boletas-pendentes/PreBoletasPendentes"));
const BatimentoBoletas = lazy(() => import("../views/pages/operacoes/batimento-boletas/BatimentoBoletas"));
const PermissionNotAuthorized = lazy(() => import("../views/pages/authentication/PermissionNotAuthorized"));

//Relatórios
const Movimentacoes = lazy(() => import("../views/pages/relatorios/movimentacoes/Movimentacoes"));
const ControleDeVencimentos = lazy(() => import("../views/pages/relatorios/controle-de-vencimentos/ControleDeVencimentos"));



const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: ""
  },
  {
    id: "home",
    title: <span style={{color: '#2B3340'}}>Home</span>,
    type: "item",
    icon: <Icon.Home fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador", "Riscos", "Tesouraria", "Operações"],
    navLink: "/home",
    router:{
      component: Home,
    },
  },
  {
    type: "groupHeader",
    groupTitle: "OPERAÇÕES"
  },
  {
    id: "boletador",
    title: "Boletador",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ['Administrador','Tesouraria'],
    navLink: "/operacoes/boletador",
    router: 
    {
      component: Boletador,
    },
  },
  {
    id: "pre-boletas-pendentes",
    title: "Pré Boletas Pendentes",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ['Administrador', "Riscos"],
    navLink: "/operacoes/pre-boletas-pendentes",
    router: {
      component: PreBoletasPendentes,
    },
  },
  {
    id: "batimento-de-boletas",
    title: "Batimentos de Boletas",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ['Administrador', "Operações"],
    navLink: "/operacoes/batimento-de-boletas",
    router:  {
      component: BatimentoBoletas,
    },
  },
  {
    type: "groupHeader",
    groupTitle: "RELATÓRIOS"
  },
  {
    id: "movimentacoes",
    title: "Movimentações",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador", "Tesouraria","Riscos", "Operações"],
    navLink: "/relatorios/movimentacoes",
    router:{
      component: Movimentacoes,
    },
  },
  {
    id: "controle-de-vencimentos",
    title: "Controle De Vencimentos",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador", "Tesouraria","Riscos", "Operações"],
    navLink: "/relatorios/controle-de-vencimentos",
    router:{
      component: ControleDeVencimentos,
    },
  },
  {
    type: "groupHeader",
    groupTitle: "CADASTROS"
  },
  {
    id: "fundos",
    title: "Fundos",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador", "Tesouraria", "Operações"],
    navLink: "/cadastros/fundos",
    router: {
      component: Fundos,
    },
  },
  {
    id: "agentes-mercados",
    title: "Agentes Mercados",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador","Tesouraria", "Operações"],
    navLink: "/cadastros/agentes-mercados",
    router: {
      component: AgentesMercados,
    },
  },
  {
    id: "ativos",
    title: "Ativos",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador", "Tesouraria", "Operações"],
    navLink: "/cadastros/ativos",
    router:{
      component: Ativos,
    },
  },
  {
    id: "configuracoes",
    title: "Configurações",
    type: "item",
    icon: <Icon.Folder fill="#2B3340" stroke="#2B3340" size={20} />,
    isNavLinkSidebar: true,
    permissions: ["Administrador", "Tesouraria", "Operações"],
    navLink: "/cadastros/configuracoes",
    router:{
      component: Configuracoes,
    },
  },
  {
    id: "boleta",
    title: "Boleta",
    isNavLinkSidebar: false,
    type: "item",
    permissions: ["Tesouraria", "Administrador"],
    navLink: "/operacoes/boleta",
    router:{
      component: IncluirEditarBoleta,
    },
  },
  {
    id: "permissao-nao-autorizada",
    title: "Permissão não autorizada",
    isNavLinkSidebar: false,
    type: "item",
    permissions: ["Administrador", "Tesouraria", "Riscos"],
    navLink: "/permissao-nao-autorizada",
    router:{
      component: PermissionNotAuthorized,
    },
  },
]

export default navigationConfig
