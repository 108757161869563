const initialState = {
    data: [],
    params: null,
    allData: [],
    totalPages: 0,
    filteredData: [],
    totalRecords: 0,
    sortIndex: [],
    addSuccess:false,
    updateSuccess: false,
    deleteSuccess:false,
    error:false,

  }

  const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [arr.length - parseInt(params.perPage), arr.length]
      return (arr3 = finalArr)
    }
  }
  
  const ConfiguracaoReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_CONFIGURACAO_INIT":
        return {
            ...state,
            data: action.data,
            toggle: !state.toggle
      }
      case "UPDATE_CONFIGURACAO_DATA":
          let dataUpdate = [...state.data]
          let indexUpdate = state.data.findIndex(item => item.id === action.data.id)
          dataUpdate[indexUpdate] = action.data
          return { ...state, data:dataUpdate,updateSuccess: true   } 
      case "UPDATE_CONFIGURACAO_FLAG":
          let updateFlag =   { ...state, ...action.obj } ;
          return  updateFlag;    
      default:
        return state
    }
  }
  
  export default ConfiguracaoReducer
  