export const resetPassword = (state = {reset:{} }, action) => {
    switch (action.type) {
      case "FORGOT_PASSWORD": {
        return { ...state, reset: action.payload }
      }
      case "FORGOT_PASSWORD_ERROR": {
        return { ...state, error: action.payload }
      }
      default: {
        return state
      }
    }
  }
  