const initialState = {
    data: [],
    params: null,
    allData: [],
    totalPages: 0,
    filteredData: [],
    totalRecords: 0,
    sortIndex: [],
    addSuccess:false,
    updateSuccess: false,
    deleteSuccess:false,
    error:false,
    fundos: [],
    corretoras: [],
    listaClasseAtivos: []
  }
const moveIndex = (arr, from, to) => {
    let el = arr[from]
    arr.splice(from, 1)
    arr.splice(to, 0, el)
  }
  
  const getIndex = (arr, arr2, arr3, params = {}) => {
    if (arr2.length > 0) {
      let startIndex = arr.findIndex(i => i.id === arr2[0].id) + 1
      let endIndex = arr.findIndex(i => i.id === arr2[arr2.length - 1].id) + 1
      let finalArr = [startIndex, endIndex]
      return (arr3 = finalArr)
    } else {
      let finalArr = [arr.length - parseInt(params.perPage), arr.length]
      return (arr3 = finalArr)
    }
  }

const ControleDeVencimentosReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_BOLETAS_CONTROLE_DE_VENCIMENTO_INIT":
            return {
                ...state,
                data: action.data,
                toggle: !state.toggle
        }
        case "GET_BOLETAS_CONTROLE_DE_VENCIMENTO_ALL_DATA":
            return {
              ...state,
              allData: action.data,
              //totalPages: action.data.length,
              totalRecords: action.data.length,
              sortIndex: getIndex(action.data, state.data, state.sortIndex)
       }
        case "GET_BOLETAS_CONTROLE_DE_VENCIMENTO_FUNDOS":
            return {
                ...state,
                fundos: action.data,
        }
        case "GET_BOLETAS_CONTROLE_DE_VENCIMENTO_CORRETORAS":
            return {
                ...state,
                corretoras: action.data,
        }
        case "GET_BOLETAS_CONTROLE_DE_VENCIMENTO_CLASSE_ATIVO":
          return {
              ...state,
              listaClasseAtivos: action.data,
        } 
        case "GET_BOLETAS_CONTROLE_DE_VENCIMENTO_LISTA_CORRETORAS":
            return {
                ...state,
                corretoras: action.data,
        }      
        default:
            return state
        }
}

export default ControleDeVencimentosReducer